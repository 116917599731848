import { render, staticRenderFns } from "./TheDeleteAccountModal.vue?vue&type=template&id=3adb18bc&scoped=true"
import script from "./TheDeleteAccountModal.vue?vue&type=script&lang=js"
export * from "./TheDeleteAccountModal.vue?vue&type=script&lang=js"
import style0 from "./TheDeleteAccountModal.vue?vue&type=style&index=0&id=3adb18bc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3adb18bc",
  null
  
)

export default component.exports