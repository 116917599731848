import { render, staticRenderFns } from "./TheItemView.vue?vue&type=template&id=25e6e7b4&scoped=true"
import script from "./TheItemView.vue?vue&type=script&lang=js"
export * from "./TheItemView.vue?vue&type=script&lang=js"
import style0 from "./TheItemView.vue?vue&type=style&index=0&id=25e6e7b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e6e7b4",
  null
  
)

export default component.exports