import { render, staticRenderFns } from "./TheSettingsNotifications.vue?vue&type=template&id=27f2bf95&scoped=true"
import script from "./TheSettingsNotifications.vue?vue&type=script&lang=js"
export * from "./TheSettingsNotifications.vue?vue&type=script&lang=js"
import style0 from "./TheSettingsNotifications.vue?vue&type=style&index=0&id=27f2bf95&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f2bf95",
  null
  
)

export default component.exports